import React from 'react'
import Layout from 'layouts';
import {Company} from 'components/site';
import {Row} from 'react-bootstrap';

export default (props) => {
  var companies = props.pageContext.companies;
    return (
      <Layout {...props} >
        <Row style={{padding: '45px 0px 0px 0px', backgroundColor: '#f7f8f8'}}>
          {companies.map((company, i) => <Company company={company} />)}
        </Row>
      </Layout>
    )
}
